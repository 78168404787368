import * as React from "react"
import {  graphql } from 'gatsby'

import TrainingPageTemplate from "../components/pageTemplates/trainingPageOverview.js"
 
// markup
const TrainingPage = ({ props, data, pageContext }) => {
  var locale = 'en'

  return ( 
    <TrainingPageTemplate data={data} 
                          locale={locale}
                          pageContext={pageContext} />
  )
}

export const query = graphql` 
query Trainings_de {
  allMdx(
    filter: {frontmatter: {Type: {eq: "Training"}
                           hide: {ne: true }}}
  ) {
    nodes {
      frontmatter {
        Excerpt
        Title
        Slogan 
        Slug
        Locale
        cssClass
        Prio
        Duration
        Level
        Events{
          StartDate
        }
        Image{
          childImageSharp {
            gatsbyImageData(width: 840, height: 400)
          }
        }
      }
    }
  }
}
`
export default TrainingPage

